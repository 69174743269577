import React from 'react';
import { connect } from 'react-redux';

import logoCapitaliza from '../../img/logo_capitaliza.png';
import logoAdvantis from '../../img/logo_advantis.png';
import logoEquantia from '../../img/logo_equantia.jpg';
import logoConfialis from '../../img/logo-confialis-retina.png'
import './style.css';

class EquantiaGroup extends React.Component {
  render() {
    const { language } = this.props;

    const languageContent = require(`../../langs/${language}`).default
      .equantiaGroup;

    return (
      <div className='equantia-group__wrapper'>
        <h1>GRUPO EQUANTIA</h1>
        <div className='equantia-info__wrapper'>
          <img src={logoEquantia} alt='Logo equantia' />
          {languageContent.equantiaParagraph}
        </div>
        <div className='confialis-info__wrapper'>
          <img src={logoConfialis} alt='Logo confialis' />
          {languageContent.confialisParagraph}
        </div>
        <div className='advantis-info__wrapper'>
          <img src={logoAdvantis} alt='Logo Advantis' />
          {languageContent.advantisParagraph}
        </div>
        <div className='capitaliza-info__wrapper'>
          <img src={logoCapitaliza} alt='Logo capitaliza' />
          {languageContent.capitalizaParagraph}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language.lang
  };
};

export default connect(
  mapStateToProps,
  null
)(EquantiaGroup);
