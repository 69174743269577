import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './Navigation.css';

class Navigation extends React.Component {
  render() {
    const { language } = this.props;

    const languageContent = require(`../../langs/${language}`).default
      .navigation;

    const {
      firstLink,
      secondLink,
      thirdLink,
      fourthLink,
      fifthLink,
      sixthLink,
      seventhLink,
      eighthLink,
      ninthLink,
      tenthLink,
      eleventhLink,
      twelfthLink
    } = languageContent;

    return (
      <nav className='navigation'>
        <Link to='/'>{firstLink}</Link>
        <Link to='/equantia-global'>{secondLink}</Link>
        <Link to='/equantia-group'>{sixthLink}</Link> 
        <Link to='/equantia-global' style={{ marginLeft: '20px' }}>
          {seventhLink}
        </Link>      
        <Link style={{ marginLeft: '40px' }} to='/commodities-market'>
        {thirdLink}
        </Link>      
        <Link style={{ marginLeft: '40px' }} to='/projects-europe'>
          {fourthLink}
        </Link>    
        <Link style={{ marginLeft: '40px' }} to='/exterior-commerce'>
          {fifthLink}
        </Link> 
        <a href='http://www.confialis.com' style={{ marginLeft: '20px' }}>
        {eleventhLink}
        </a>
        <a
          href='http://advantiscorporate.com'
          style={{ marginLeft: '20px' }}
        >
          {eighthLink}
          </a>
        <a href='http://www.capitaliza.es' style={{ marginLeft: '20px' }}>
          {twelfthLink}
        </a>
        <Link to='/responsibilities'>{ninthLink}</Link>
        <Link to='/contact'>{tenthLink}</Link>
      </nav>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language.lang
  };
};

export default connect(
  mapStateToProps,
  null
)(Navigation);
