import React from 'react';
import { connect } from 'react-redux';

import './style.css';
import commoditiesOne from '../../img/commodities_01.jpg';
import commoditiesTwo from '../../img/commodities_02.jpg';

class CommoditiesMarket extends React.Component {
  render() {
    const { language } = this.props;

    const languageContent = require(`../../langs/${language}`).default
      .commoditiesMarket;

    return (
      <div className='commodities-market__wrapper'>
        <h2>COMMODITIES MARKET</h2>
        <br />
        <p>
          {languageContent.firstParagraph.props.children}
          <br /> <br />
          {languageContent.secondParagraph}
        </p>
        <br />
        <div className='commodities-gallery'>
          <img src={commoditiesTwo} alt='Commodity two' />
          <img src={commoditiesOne} alt='Commodity one' />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language.lang
  };
};

export default connect(
  mapStateToProps,
  null
)(CommoditiesMarket);
