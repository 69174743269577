import React from 'react';
import { connect } from 'react-redux';

import './style.css';

class EuropeanProjects extends React.Component {
  render() {
    const { language } = this.props;

    const languageContent = require(`../../langs/${language}`).default
      .europeanProjects;

    return (
      <div className='european-projects__wrapper'>
        <h2>{languageContent.firstTitle}</h2>
        <br />
        <p>{languageContent.firstParagraph.props.children}</p>
        <br />
        <p>{languageContent.secondParagraph.props.children}</p>
        <br />
        <p>{languageContent.thirdParagraph.props.children}</p>
        <br />
        <p>{languageContent.fourthParagraph.props.children}</p>
        <br />
        <p>{languageContent.fifthParagraph.props.children}</p>
        <br />
        <p>{languageContent.sixthParagraph.props.children}</p>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language.lang
  };
};

export default connect(
  mapStateToProps,
  null
)(EuropeanProjects);
