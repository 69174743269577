import React from 'react';
import fundamentals from '../../img/fundamentos.gif';

import { connect } from 'react-redux';

import './styles.css';

class EquantiaGlobal extends React.Component {
  render() {
    const { language } = this.props;

    const languageContent = require(`../../langs/${language}`).default
      .equantiaGlobalBusiness;

    return (
      <div className='equantia-global__wrapper'>
        <h2>EQUANTIA, GLOBAL BUSINESS</h2>
        <br />
        <p>
          {languageContent.firstParagraph.props.children}
          {languageContent.secondParagraph.props.children}
        </p>
        <br />
        <h2>MISIÓN</h2>
        <br />
        {languageContent.mission.firstParagraph.props.children}
        <br />
        <br />
        <h4>{languageContent.mission.firstTitle.props.children}</h4>
        <br />
        <p>{languageContent.mission.secondParagraph.props.children}</p>
        <br />
        <h4>{languageContent.mission.secondTitle}</h4>
        <br />
        <p>{languageContent.mission.thirdParagraph.props.children}</p>
        <br />
        <ul className='culture-model'>
          <li>{languageContent.mission.list.firstLi}</li>
          <li>{languageContent.mission.list.secondLi}</li>
          <li>{languageContent.mission.list.thirdLink}</li>
          <li>{languageContent.mission.list.fourthLink}</li>
        </ul>
        <br />
        <p>{languageContent.mission.fourthParagraph.props.children}</p>
        <br />
        <img src={fundamentals} alt='Fundamentals' />
        <br />
        <br />
        <p>{languageContent.mission.fifthParagraph}</p>
        <br />
        <ul className='culture-model'>
          <li>{languageContent.mission.secondList.firstLi}</li>
          <li>{languageContent.mission.secondList.secondLi}</li>
          <li>{languageContent.mission.secondList.thirdLi}</li>
          <li>{languageContent.mission.secondList.fourthLi}</li>
        </ul>
        <br />
        <p>{languageContent.mission.lastParagraph.props.children}</p>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language.lang
  };
};

export default connect(
  mapStateToProps,
  null
)(EquantiaGlobal);
