import React from 'react';

import './index.css';
import enterpriseImage from '../../img/logo_enterprise.jpg';
import igapeImage from '../../img/agenteigape.jpg';

const Certificates = props => {
  return (
    <div className='certificates__wrapper'>
      <h2>CERTIFICACIONES</h2>
      <br />
      <div className='certificate-images'>
        <img src={enterpriseImage} alt='Enterprise Europe' />
        <img src={igapeImage} alt='Igape' />
      </div>
    </div>
  );
};

export default Certificates;
