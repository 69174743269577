import React from 'react';
import { connect } from 'react-redux';

import './style.css';
import equantiaMap from '../../img/equantia_map.png';

function HomeContent(props) {
  
  const languageContent = require(`../../langs/${props.language}`).default.home;

  const {
    firstParagraph: {
      props: { children: firstParagraph }
    },
    secondParagraph: {
      props: { children: secondParagraph }
    },
    newsParagraphTitle: {
      props: { children: newsParagraphTitle }
    },
    newsParagraph: {
      props: { children: newsParagraph  }
    },
    newsReadMore: {
      props: { children: newsReadMore }
    }
  } = languageContent;

  return (
    <div className='home-content__wrapper'>
      <p>
        {firstParagraph}
        <br />
        <br />
        {secondParagraph}
      </p>
        <div className='second-section__wrapper'>
          <img src={equantiaMap} alt='Equantia Map' />
          <div className='news__wrapper'>
            <h3>{newsParagraphTitle}</h3>
            <br />
            <p>{newsParagraph}</p>
            <br />
            <a href='https://www.aepd.es/index.html'>{newsReadMore}</a>
          </div>
        </div>
    </div>
  );
}


const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language.lang
  };
};

export default connect(
  mapStateToProps,
  null
)(HomeContent);
