import React from 'react';

import equantiaImage from '../../img/00_eq_equantia.jpg';
import confialisImage from '../../img/01_eq_confialis.jpg';
import capitalizaImage from '../../img/02_eq04.jpg';

import './Slideshow.css';
import Navigation from '../Navigation/Navigation';

class SlideShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [
        equantiaImage,
        confialisImage,
        capitalizaImage,
      ],
      indexAt: 0
    };
  }

  renderCurrentImage = () => {
    return this.state.images.map((image, index) => (
      <img
        height='259px'
        width='639px'
        className={index === this.state.indexAt ? 'showing' : null}
        key={index}
        src={image}
        alt='equalia'
      />
    ));
  };

  render() {
    const slideshowInterval = setInterval(() => {
      this.setState(prevState => {
        if (prevState.indexAt === 2) {
          return {
            indexAt: 0
          };
        }
        return {
          indexAt: prevState.indexAt + 1
        };
      });
      clearInterval(slideshowInterval);
    }, 4000);

    return (
      <div className='slideshow__wrapper'>
        {this.renderCurrentImage()}
        <div className='slideshow-menu__wrapper'>
          <Navigation />
        </div>
      </div>
    );
  }
}

export default SlideShow;
