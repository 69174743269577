import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeLanguage } from '../../actions';
import detectBrowserLanguage from 'detect-browser-language';

import './App.css';
import Header from '../Header/Header';
import SlideShow from '../Slideshow/Slideshow';
import HomeContent from '../HomeContent';
import Footer from '../Footer';
import EquantiaGlobal from '../EquantiaGlobal';
import EquantiaGroup from '../EquantiaGroup';
import Responsibilities from '../Responsibilities';
import Contact from '../Contact';
import LegalTerms from '../LegalTerms';
import CommoditiesMarket from '../CommoditiesMarket';
import EuropeanProjects from '../EuropeanProjects';
import ExteriorCommerce from '../ExteriorCommerce';
import Certificates from '../Certificates';

class App extends React.Component {
  getBrowserLanguage() {
    const lang = detectBrowserLanguage();
    if (lang.includes('es')) {
      this.props.changeLanguage('es');
    } else {
      this.props.changeLanguage('en');
    }
  }

  render() {
    this.getBrowserLanguage();
    return (
      <div className='app__wrapper'>
        <Header />
        <SlideShow />
        <div className='content__wrapper'>
          <Switch>
            <Route exact path='/' component={HomeContent} />
            <Route exact path='/equantia-global' component={EquantiaGlobal} />
            <Route exact path='/equantia-group' component={EquantiaGroup} />
            <Route
              exact
              path='/responsibilities'
              component={Responsibilities}
            />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/legal-terms' component={LegalTerms} />
            <Route
              exact
              path='/commodities-market'
              component={CommoditiesMarket}
            />
            <Route exact path='/projects-europe' component={EuropeanProjects} />
            <Route
              exact
              path='/exterior-commerce'
              component={ExteriorCommerce}
            />
            <Route exact path='/certificates' component={Certificates} />
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(
  null,
  { changeLanguage }
)(App);
