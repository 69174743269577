import React from 'react';
import { connect } from 'react-redux';

import './style.css';

class Contact extends React.Component {
  render() {
    const { language } = this.props;

    const languageContent = require(`../../langs/${language}`).default.contact;

    return (
      <div className='contact__wrapper'>
        <h2>{languageContent.title}</h2>
        <br />
        <div className='all-contacts'>
          <div className='contact-card'>
            <h5>Equantia España</h5>
            <p>Aragón, 197</p>
            <p>36206, Vigo (SPAIN)</p>
            <p>Tel. +34 986 139 007</p>
            <p>Fax. +34 886 113 730</p>
            <a href='mailto:equantia@equantia.com'>equantia@equantia.com</a>
          </div>
          <div className='contact-card'>
            <h5>Equantia Bálticos</h5>
            <p>Pulkveža Brieža iela Nr. 8-1</p>
            <p>LV-1010 - Riga (LATVIA)</p>
            <a href='mailto:latvia@equantia.com'>latvia@equantia.com</a>
          </div>
          <div className='contact-card'>
            <h5>Equantia Bulgaria</h5>
            <p>c/ Kiril Vidinski 6-8, planta 1, oficina 5, Sofia</p>
            <a href='mailto:bulgaria@equantia.com'>bulgaria@equantia.com</a>
          </div>
        </div>
        <form id='contact-form' action='#'>
          <div className='field'>
            <label htmlFor='name'>{languageContent.formName}</label>
            <input type='text' id='name' name='name' />
          </div>
          <div className='field'>
            <label htmlFor='email'>E-mail</label>
            <input type='email' name='email' id='email' />
          </div>
          <div className='field'>
            <label htmlFor='message-subject'>
              {languageContent.formMessageSubject}
            </label>
            <input type='text' />
          </div>
          <div className='field'>
            <label htmlFor='message'>{languageContent.formMessage}</label>
            <textarea name='message' id='message' cols='40' rows='6'></textarea>
          </div>
          <input type='checkbox' name='legal' id='legal' />
          {languageContent.formAcceptTerms.props.children}
          <br />
          <button>{languageContent.formSubmitBtnText}</button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language.lang
  };
};

export default connect(
  mapStateToProps,
  null
)(Contact);
