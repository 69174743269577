import React from 'react';
import spanishFlag from '../../img/es.gif';
import englishFlag from '../../img/en.gif';
import { connect } from 'react-redux';
import { changeLanguage } from '../../actions';

import Logo from '../Logo';
import './Header.css';

const Header = props => {
  return (
    <header className='main__header'>
      <Logo />
      <div className='language-selector'>
        <img
          onClick={() => props.changeLanguage('es')}
          src={spanishFlag}
          alt='Spanish flag'
        />
        <img
          onClick={() => props.changeLanguage('en')}
          src={englishFlag}
          alt='English flag'
        />
      </div>
    </header>
  );
};

export default connect(
  null,
  { changeLanguage }
)(Header);
