import React from 'react';
import { connect } from 'react-redux';

import './style.css';

class Responsibilities extends React.Component {
  render() {
    const { language } = this.props;

    const languageContent = require(`../../langs/${language}`).default
      .responsibilities;

    return (
      <div className='responsibilites-wrapper'>
        <h2>{languageContent.title}</h2>
        <br />
        <h3>Equantia Global Business, S.L.</h3>
        <br />
        <p>
          {languageContent.firstParagraph.props.children}
          <br /> <br />
          {languageContent.secondParagraph.props.children}
        </p>
        <br />
        <ol className='clausules'>
          <li>{languageContent.orderedList.firstLi}</li>
          <br />
          <li>{languageContent.orderedList.secondLi}</li>
          <br />
          <li>{languageContent.orderedList.thirdLi}</li>
          <br />
          <li>{languageContent.orderedList.fourthLi}</li>
          <br />
          <li>{languageContent.orderedList.fifthLi}</li>
          <br />
          <li>{languageContent.orderedList.sixthLi}</li>
          <br />
          <li>{languageContent.orderedList.seventhLi}</li>
          <br />
          <li>{languageContent.orderedList.eighthLi}</li>
        </ol>
        <br />
        <p>{languageContent.thirdParagraph}</p>
        <br />
        {languageContent.fourthParagraph}
        <a href='mailto:info@confialis.com'>info@confialis.com.</a>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language.lang
  };
};

export default connect(
  mapStateToProps,
  null
)(Responsibilities);
