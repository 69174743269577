import React from 'react';

import { Link } from 'react-router-dom';

const content = {
  navigation: {
    firstLink: 'Inicio',
    secondLink: 'EQUANTIA GLOBAL BUSINESS',
    thirdLink: 'Commodities Market',
    fourthLink: 'Proyectos Europeos',
    fifthLink: 'Comercio Exterior',
    sixthLink: 'Grupo Equantia',
    seventhLink: 'EQUANTIA, internacionalización',
    eighthLink: 'ADVANTIS, software a medida',
    eleventhLink: 'CONFIALIS, protección de datos',
    twelfthLink: 'CAPITALIZA, blanqueo de capitales',
    ninthLink: 'Responsabilidad social corporativa',
    tenthLink: 'Contacta'
  },
  home: {
    firstParagraph: (
      <p>
        Si al inicio de cada ejercicio económico el objetivo de su empresa es la
        ampliación de sus socios comerciales, su ámbito de influencia, su
        conocimiento del sector y sus competidores y, en definitiva, mejorar su
        posicionamiento en el mercado, entonces el grupo EQUANTIA le puede
        ayudar.
      </p>
    ),
    secondParagraph: (
      <p>
        Gracias a nuestra red de oficinas propias y colaboradores
        internacionales, su empresa podrá acceder a un mercado más amplio,
        alcanzar una mayor estabilidad en sus ventas, mejorar su imagen externa
        e interna, eliminar las barreras culturales, diversificar riesgos,
        compensar sus resultados, acceder a nuevas vías de financiación
        internacional, aprender de experiencias externas o aprovecharse de las
        economías de escala a su alcance.
      </p>
    ),
    newsParagraphTitle: (
      <h3>Reglamento Europeo de Protección de Datos (RGPD) – Transferencias Internacionales</h3>
    ),
    newsParagraph: (
      <p>
      El Reglamento General de Protección de Datos UE 2016/679 introduce novedades importantes
      en el régimen de autorización y notificación previa de las transferencias internacionales de
      datos. Si desea saber más del tema póngase en contacto con www.confialis.com.
      </p>
    ),
    newsReadMore: (
      <p>Leer más</p>
    ),
  },
  equantiaGlobalBusiness: {
    firstParagraph: (
      <p>
        <strong>EQUANTIA, GLOBAL BUSINESS </strong> es una Organización de
        consultoría fundada en 2003 y orientada a facilitar la
        internacionalización de los diferentes sectores empresariales y sus
        empresas, así como el desarrollo socioeconómico de las mismas, tanto en
        al ámbito nacional como de las euro regiones. Para ello cuenta con un
        equipo humano altamente cualificado y cuyo trabajo se fundamenta en la
        Confianza, la Responsabilidad, la Sinceridad y el Trabajo en equipo,
        como mejor respuesta a la misma Confianza depositada por nuestros
        clientes.
        <br /> <br />
      </p>
    ),
    secondParagraph: (
      <p>
        Las relaciones de EQUANTIA con instituciones y empresas de otros países,
        son uno de los factores de éxito que permiten hacer realidad nuestro
        proyecto de colaboración activa en la internacionalización de las
        empresas.
      </p>
    ),
    mission: {
      firstParagraph: (
        <p>
          <p>
            Obtener una alta rentabilidad, así como el desarrollo personal,
            profesional y económico de todos los integrantes de la Empresa.
          </p>
        </p>
      ),
      firstTitle: <h4>El Negocio</h4>,
      secondTitle: 'Cultura',
      secondParagraph: (
        <p>
          LA EMPRESA se dedicará a la comercialización de servicios y productos
          asociados a la internacionalización de los mercados y a promover las
          relaciones transnacionales. Nuestra meta es crear valor y satisfacción
          para los clientes de forma permanente, creando una Empresa competitiva
          que satisfaga los deseos de los propietarios, directivos y empleados,
          y para ello: Aprovecharemos al máximo los conocimientos y capacidades
          del personal, comprometiéndonos con la mejora continua tanto en
          calidad de producto como en procesos, con el objetivo de que sientan
          la empresa como suya Diferenciarnos con una gran imagen de Marca y con
          la fidelización del cliente Estrecha relación con todos nuestros
          colaboradores
        </p>
      ),
      thirdParagraph: (
        <p>
          Todo esto lo basaremos en un modelo de Cultura de Empresa regido por:
        </p>
      ),
      list: {
        firstLi: 'El fomento de la participación y trabajo en equipo',
        secondLi: 'El diálogo',
        thirdLink:
          'La credibilidad y confianza de las relaciones tanto internas como externas',
        fourthLink: 'El fomento del desarrollo profesional de todo el personal'
      },
      fourthParagraph: (
        <p>
          Por tanto nuestra empresa depende de valores de desarrollo, quedando
          los valores de control para el mantenimiento y administración de la
          riqueza generada, que fundamentamos en:
        </p>
      ),
      fifthParagraph:
        'Todo esto nos lleva a 4 valores fundamentales de éxito en nuestra Organización: CRST (Confianza, Responsabilidad, Sinceridad y Trabajo en Equipo).',
      secondList: {
        firstLi: 'Confianza: Aportamos tranquilidad y garantías a buen precio.',
        secondLi:
          'Responsabilidad: Avanzamos con la aportación de los conocimientos de cada profesional después del análisis profundo de su trabajo.',
        thirdLi: 'Sinceridad: Progresamos con honestidad diálogo y respeto.',
        fourthLi:
          'Trabajo en Equipo: Compartimos ideas, ilusión y experiencia, para construir un gran proyecto para todos.'
      },
      lastParagraph: (
        <p>
          Como consecuencia de estos 4 valores, todos aportamos:
          autoorganización, libertad, creatividad, intuición, variedad,
          aprendizaje de errores, entusiasmo, equidad, debate, alegría, calidez,
          potenciación, provocación y confianza.
        </p>
      )
    }
  },
  commoditiesMarket: {
    firstParagraph: (
      <p>
        Nuestro programa de intermediación establece un vínculo permanente con
        las empresas. En mercados volátiles como los actuales, la experiencia
        internacional acumulada y el profundo análisis de cada caso, nos permite
        trabajar en el mercado de Commodities, como mandatarios directos de
        compradores y vendedores de todos los continentes. Podemos operar con
        cualquier Commodity y muy especialmente en los mercados energéticos,
        combustibles y de metales.
      </p>
    ),
    secondParagraph:
      'Investigamos los credenciales de nuestros interlocutores, previo a cualquier inicio de procedimiento. La seguridad de las transacciones es crucial para nuestros clientes y, por tanto, para nosotros. El primer contacto con las actividades de “Trading” del Grupo EQUANTIA, debe ser realizado por medio del correo electrónico info@equantia.com, y una vez verificada la veracidad de dichos contactos, la dirección del grupo se comunicará directamente con los interlocutores.'
  },
  europeanProjects: {
    firstTitle: 'PROYECTOS EUROPEOS',
    firstParagraph: (
      <p>
        La obtención de ayudas para <strong>financiación de proyectos</strong>{' '}
        no pasa exclusivamente por los bancos con los que trabaja habitualmente,
        también por la administración pública y de una forma importante por los
        proyectos europeos.
      </p>
    ),
    secondParagraph: (
      <p>
        A través de la gestión de proyectos europeos relacionados con el empleo
        (formación, espíritu empresarial, etc),{' '}
        <strong>
          la igualdad de oportunidades, responsabilidad social, gestión de la
          edad, investigación en el campo de las nuevas tecnologías y de la
          sociedad de la información, transferencia de buenas prácticas
        </strong>
        , se aportan soluciones a las problemáticas mencionadas.
      </p>
    ),
    thirdParagraph: (
      <p>
        Nos ponemos al servicio de asociaciones, organismos públicos, entidades
        privadas, etc. que hayan detectado una necesidad, carencia o
        problemática en un sector económico determinado o área geográfica y a la
        que se debe dar una solución.
      </p>
    ),
    fourthParagraph: (
      <p>
        El Marco de estos proyectos es tan dispar como las propias necesidades
        de las Organizaciones; yendo desde{' '}
        <strong>
          “Programas de investigación específicos para Pymes”, “Enfoques
          innovadores en la gestión del Cambio”, “Análisis de necesidades
          sectoriales”, etc.
        </strong>
      </p>
    ),
    fifthParagraph: (
      <p>
        Uno de los aspectos fundamentales de esta sección, además de dar
        respuesta a una problemática específica, es la{' '}
        <strong>dimensión transacional</strong>que tienen estos proyectos. Nos
        permite ponernos en contacto con organizaciones públicas y privadas de
        otros países, para el intercambio de experiencias y buenas prácticas,
        con lo que las soluciones a una problemática concreta son{' '}
        <strong>mucho más enriquecedoras.</strong>
      </p>
    ),
    sixthParagraph: (
      <p>
        La participación en este tipo de proyectos contribuye además a la
        internacionalización de las empresas (conocimiento de nuevas formas de
        proceder en entornos empresariales similares así como apertura de nuevos
        mercados), ya que se{' '}
        <strong>
          generan redes de socios cuya colaboración puede mantenerse más allá de
          un proyecto concreto.
        </strong>
      </p>
    )
  },
  exteriorCommerce: {
    firstTitle: 'COMERCIO EXTERIOR',
    firstParagraph: (
      <p>
        <strong>Equantia</strong>, a través de una red mundial de{' '}
        <strong>Partners</strong>, le propone su servicio de consultoría
        especializada para la confección de su Plan de Internacionalización,
        herramienta fundamental y guión básico del desarrollo exterior de su
        empresa, conteniendo:
      </p>
    ),
    orderedList: {
      firstLi:
        'Estrategias a medida (Análisis de la exportabilidad de la empresa y sus productos)',
      secondLi: 'Análisis de viabilidad y estudios de mercados',
      thirdLi: 'Búsqueda de socios o distribuidores adecuados',
      fourthLi: 'Realizando nosotros la distribución directamente',
      fifthLi: 'Captación del espacio de inversión adecuado',
      sixthLi: 'Búsqueda de apoyos financieros adecuados',
      seventhLi:
        'Creación de sociedades, selección de personal, localización de oficinas',
      eighthLi: 'Estrategia de marketing',
      ninthLi: 'Maximización de beneficio fiscal'
    },
    secondParagraph: (
      <p>
        Nuestro objetivo es convertirnos en el Dpto. de Exportación de su
        empresa, dándole un trato personalizado con el que establecemos unos{' '}
        <strong>objetivos y estrategias</strong> para su empresa.
      </p>
    ),
    thirdParagraph: <p>Nuestros servicios tienen tres niveles:</p>,
    secondOrderedList: {
      firstLi:
        'Establecemos los contactos necesarios con los potenciales clientes, y usted le da continuidad a la relación. Envío de prospecciones comerciales, de acuerdo con usted, y remisión de las conclusiones, sin duda de gran valía para la toma de decisiones en su empresa.',
      secondLi:
        'Establecemos los contactos, pero, esta vez conducimos todas las negociaciones, con los viajes necesarios al cliente así como cualquier gestión necesaria ante el cliente y las administraciones públicas, para el buen fin de la operación.',
      thirdLi:
        'Actuamos como Agentes Comerciales, cobrando una comisión por las ventas, teniendo en todo momento ustedes los datos de estudios de mercado, competencia, nuevas posibilidades de negocios, posibilidades de alianzas con otras empresas, etc. que nosotros manejemos.'
    },
    fourthParagraph: (
      <p>
        Recuerde que el objetivo fundamental es no solamente el{' '}
        <strong>incremento de las ventas</strong>, sino la ampliación de sus
        socios comerciales, su mercado de influencia, su conocimiento del
        mercado en general y de sus competidores, y en definitiva, su{' '}
        <strong>mejor posicionamiento en el mercado.</strong>
      </p>
    )
  },
  equantiaGroup: {
    equantiaParagraph: (
      <p>
        <strong>EQUANTIA</strong> es una Consultora especializada en&nbsp;
        <strong>Comercio Exterior</strong> y <strong>Proyectos Europeos</strong>
        . Realizamos Planes de Internacionalización completos, Misiones
        Comerciales individuales o colectivas, Búsqueda de socios y
        distribuidores e Intermediación comercial directa, en cualquiera de los
        países donde tenemos oficinas propias o alianzas estratégicas. Además
        trabajamos con proyectos socio-económicos en la Unión Europea, desde la
        creación, el desarrollo y la ejecución.
      </p>
    ),
    capitalizaParagraph: (
      <p>
        <strong>CAPITALIZA</strong> es una Organización de consultoría orientada a facilitar los
        procesos de cambio dentro de las organizaciones y a transformar los
        actuales modelos de gestión para posibilitar niveles de productividad y
        rentabilidad superiores. Realizamos Consultoría estratégica y Auditoría
        en materia de Responsabilidad Social Corporativa. Implantación de
        Prevención del Blanqueo de Capitales, de sistemas de gestión de RSC,
        Planes de Igualdad, Códigos Éticos, Memorias de sostenibilidad,
        Programas sociales, e Implantación de la norma SGE21.
      </p>
    ),
    advantisParagraph: (
      <p>
        <strong>ADVANTIS CORPORATE</strong> es una empresa especializada en
        proveer servicios de programación en software a medida y aplicaciones
        móviles. Estamos enfocados, principalmente, en trasformar las
        necesidades de los clientes en productos funcionales asegurando calidad,
        soporte y servicios post-venta. Desarrollamos todo tipo de Apps, para
        IOS y Android.
      </p>
    ),
    confialisParagraph: (
      <p><strong>CONFIALIS</strong> presta sus servicios acorde a la normativa vigente en materia de protección de datos: Reglamento General de Protección de Datos (RGPD) y la Ley Orgánica de Protección de Datos de Carácter Personal y demás normativa que sea de aplicación indirecta en materia de protección de datos personales. Completamos la aplicación de esta normativa con las directrices establecidas en la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico.</p>
    )
  },
  responsibilities: {
    title: 'RESPONSABILIDAD SOCIAL CORPORATIVA',
    firstParagraph: (
      <p>
        <strong>Equantia Global Business, S.L.</strong> ha afirmado su
        compromiso ético en todas sus áreas de gestión. Este compromiso integra
        tanto los valores que ha adoptado como propios (la confianza, la
        responsabilidad, la sinceridad y el trabajo en equipo), como la misión y
        visión de la empresa.,
      </p>
    ),
    secondParagraph: (
      <p>
        Y de este compromiso se deriva la{' '}
        <strong>Política de Gestión Ética y Socialmente Responsable</strong> que
        regulará el Sistema de Gestión Ética de la empresa. Esta política se
        rige por las siguientes cláusulas:
      </p>
    ),
    orderedList: {
      firstLi:
        'Cumplimiento del ordenamiento jurídico vigente, tanto formal como materialmente, y efectividad de los compromisos adquiridos en materia ambiental y socioeconómica. Transmisión del compromiso de la empresa a las partes interesadas de la misma.',
      secondLi:
        'Información sobre el compromiso ético de la empresa a los clientes a través del documento de contratación y en la prestación de servicios orientados a satisfacer la misión establecida por Equantia Global Business, S.L.',
      thirdLi:
        'Exigencia a las empresas colaboradoras, contratistas o proveedoras del cumplimiento de la legislación en materia ambiental y social. Esta exigencia se materializará a través de la política de compra y contratación de bienes y servicios.',
      fourthLi:
        'Desarrollo de los principios del Sistema de Gestión Ética en todas las áreas de actividad de la empresa, con objetivos y programas de actuación concretos de carácter periódico. Serán complementarios a las demás políticas, especialmente con la de calidad y gestión de los recursos humanos.',
      fifthLi:
        'Establecimiento, por parte de la empresa, de mecanismos de seguimiento del sistema de gestión ética, basado en el autocontrol, y en la utilización de procedimientos de atribución de responsabilidades y supervisión de la gestión.',
      sixthLi:
        'Utilización eficiente de los recursos, tales como el agua o la energía, minimizando su consumo, la generación de residuos y los niveles de contaminación e impacto.',
      seventhLi:
        'Apoyo de la adopción de compromisos específicos de mejora ambiental y social continua sobre la base de la implantación de programas, certificación y auditoría periódica del sistema de gestión ética de la empresa.',
      eighthLi:
        'Implantación de medidas para la formación, sensibilización y motivación del personal de Equantia Global Business, S.L. Se ratifica el compromiso de la empresa con la gestión ética de los recursos humanos, basada en el cumplimiento de la normativa laboral y de prevención de riesgos, la garantía de igualdad en la selección y tratamiento del personal, sin diferencia de sexo, grupo étnico o cualquier otra condición social, la adopción de medidas de conciliación de la vida laboral y familiar y la atención a sus necesidades y satisfacción de sus compromisos.'
    },
    thirdParagraph:
      'La Dirección de Equantia Global Business, S.L., hace suya esta política y se compromete a impulsarla.',
    fourthParagraph: (
      <p>
        Si desea consultar nuestro Código de Conducta Ética y Socialmente
        Responsable solicítelo dirigiéndose al correo
      </p>
    )
  },
  contact: {
    title: 'CONTACTA CON EQUANTIA',
    formName: 'Nombre y apellidos',
    formMessageSubject: 'Tema del mensaje',
    formMessage: 'Escriba su mensaje',
    formAcceptTerms: (
      <>
        He leído el <Link to='/legal-terms'>AVISO LEGAL</Link> y acepto
      </>
    ),
    formSubmitBtnText: 'Enviar'
  },

  privacyPolicy: {
    title: 'POLÍTICA DE PRIVACIDAD',
    firstParagraph: (
      <p>
        <strong>EQUANTIA GLOBAL BUSINESS, S.L.</strong> en cumplimiento de la
        Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
        Información y de Comercio Electrónico, le informa:
      </p>
    ),
    firstOrderedList: {
      firstLink: 'Su denominación social es EQUANTIA GLOBAL BUSINESS, S.L.',
      secondLink: 'Su NIF es B36932135',
      thirdLink:
        'Su domicilio se ubica en Calle Aragón 197 Interior, 36206 Vigo (Pontevedra)',
      fourthLink: 'Su teléfono de contacto es 986 139 007',
      fifthLink:
        'Está inscrita en el Registro Mercantil de Pontevedra, Tomo 2918, Folio 195, Inscripción 1ª, de la Hoja nº PO-34171',
      sixthLink: (
        <>
          Y dispone de la siguiente dirección de correo electrónico de contacto
          <a href='mailto:equantia@equantia.com'> equantia@equantia.com'</a>
        </>
      )
    },
    secondTitle: 'PROTECCIÓN DE DATOS PERSONALES',
    secondParagraph:
      'Los datos personales vinculados a esta web respetan las exigencias del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y demás legislación y normativa vigente en materia de protección de datos.',
    thirdTitle: '¿Quién es el Responsable del tratamiento?',
    address: 'Dirección: Calle Aragón 197 Interior, 36206 Vigo (Pontevedra)',
    mail: 'Correo electrónico:',
    fourthTitle: '¿Con que finalidad tratamos sus datos personales?',
    thirdParagraph:
      'De acuerdo con las distintas secciones de nuestra web, tratamos sus datos con las siguientes con la finalidad de gestionar el envío de la información solicitada a través del apartado de contacto.',
    fifthTitle: '¿Durante cuánto tiempo conservaremos sus datos personales?',
    fourthParagraph:
      'Sus datos serán conservados el tiempo necesario para atender a su solicitud y, en su caso, el envío de la información solicitada.',
    sixthTitle: '¿Cuál es la legitimación para el tratamiento de sus datos?',
    fifthParagraph:
      'La base legal para el tratamiento de sus datos será el consentimiento prestado al hacer uso del formulario de contacto.',
    seventhTitle: '¿A qué destinatarios se comunicarán sus datos?',
    sixthParagraph: 'No se realizarán comunicaciones de datos a terceros.',
    eighthTitle: '¿Se realizarán transferencia de datos a terceros países?',
    seventhParagraph:
      'No se van a producir Transferencias Internacionales de datos.',
    ninthTitle:
      '¿Cuáles son sus derechos cuando nos facilita sus datos personales?',
    secondList: {
      firstLi:
        'Acceso: derecho a obtener confirmación sobre si estamos tratando datos personales suyos o no, a saber, cuáles son, para que se usan, cuanto tiempo se van a guardar, el origen de los mismos y si se han comunicado o se van a comunicar a un tercero.',
      secondLi:
        'Rectificación: derecho a solicitar la rectificación de los datos inexactos y a que se completen los datos personales incompletos.',
      thirdLi:
        'Supresión: derecho a solicitar la supresión de los datos de carácter personal cuando sean inadecuados, excesivos o ya no sean necesarios para los fines para los que fueron recogidos, incluido el derecho al olvido.',
      fourthLi:
        'Oposición: derecho a oponerse, en determinadas circunstancias, a que se realice el tratamiento de sus datos personales o a solicitar que se cese en el tratamiento.',
      fifthLi:
        'Limitación del Tratamiento: derecho a solicitar, en las circunstancias establecidas legalmente, que no se traten sus datos más allá de la mera conservación de los mismos.',
      sixthLi:
        'Portabilidad: derecho a recibir los datos de carácter personal en un formato estructurado, de uso común y lectura mecánica, y poder transmitirlos a otro responsable, siempre que sea técnicamente posible.'
    },
    tenthTitle: '¿Tendrá la posibilidad de retirar el consentimiento?',
    ninthParagraph:
      'Tendrá la posibilidad y el derecho de retirar su consentimiento para cualquier finalidad específica otorgada en su momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.',
    eleventhTitle: '¿Dónde podrá ejercitar sus derechos?',
    tenthParagraph:
      'El ejercicio de derechos deberá efectuarse por escrito adjuntando fotocopia de su documento identificativo, y dirigirla a la dirección anteriormente citada. Así mismo, ponemos a su disposición modelos para su ejercicio, que pueden recogerse en nuestras instalaciones o solicitarlos enviando un correo electrónico a',
    twelfthParagraph: 'Para más información: www.agpd.es',
    thirteenthParagraph:
      'Derecho de reclamación ante la Autoridad de Control: En el caso de que no se hayan respetado sus derechos puede presentar una reclamación dirigiéndose por escrito a la Agencia Española de Protección de Datos sita en la calle Jorge Juan, 6-28001-Madrid o utilizar la sede electrónica: https://sedeagpd.gob.',
    fourteenthParagraph:
      'En ambos casos, deberá acompañar la documentación pertinente.',
    twelfthTitle: 'Acceso y contenidos de la web',
    fifteenthParagraph:
      'El acceso a la web es público y la información contenida en la misma tiene carácter meramente informativo. La política de la empresa es tratar de que los contenidos estén siempre actualizados, pero puede suceder que no lo estén.',
    sixteenthParagraph:
      'La función de los link o enlaces que aparecen en esta página es exclusivamente la de informar a los usuarios y a los clientes sobre la existencia de otras fuentes de información sobre la materia de internet, donde podrá ampliar o completar los datos ofrecidos en esta página.',
    seventeenthParagraph:
      'En todo caso EQUANTIA no asume responsabilidad derivada de los contenidos enlazados desde su página web, ni puede garantizar la ausencia de virus u otros elementos en los mismos que puedan producir alteraciones en el sistema informático, en los documentos o ficheros del usuario, excluyendo cualquier responsabilidad por los daños de cualquier clase causados al usuario por este motivo.',
    thirteenthTitle: 'Propiedad intelectual',
    eighteenthParagraph:
      'Los derechos de propiedad intelectual de los artículos publicados son de EQUANTIA. La utilización no autorizada de la información contenida en esta web, así como la lesión de los derechos de Propiedad Intelectual o Industrial de EQUANTIA, dará lugar a las responsabilidades legalmente establecidas. Tanto el acceso a esta web como el uso que pueda hacerse de la información contenida en la misma, es responsabilidad exclusiva de quien lo realiza.',
    fourteenthTitle: 'Responsabilidades',
    nineteenthParagraph:
      'EQUANTIA no garantiza la inexistencia de errores en el acceso al sitio Web o en su contenido, intentando en todo caso que éste se encuentre actualizado, aunque empleará todas las medidas que estén a su alcance para evitarlos o subsanarlos.',
    fifteenthTitle: 'Legislación aplicable',
    twentiethParagraph:
      'El presente Aviso Legal se rige por la legislación española.',
    sixteenthTitle: 'INFORMACIÓN BÁSICA FORMULARIOS DE CONTACTO WEB',
    twentyfirstParagraph: (
      <p>
        <strong>Normativa: </strong>Reglamento (UE) 2016/679 RGPD.
      </p>
    ),
    twentysecondParagraph: (
      <p>
        <strong>Responsable: EQUANTIA GLOBAL BUSINESS, S.L.</strong>
      </p>
    ),
    twentythirdParagraph: (
      <p>
        <strong>Finalidad: </strong>Gestionar las solicitudes de información que
        se realizan a través de la página web.
      </p>
    ),
    twentyfourthParagraph: (
      <p>
        <strong>Legitimación: </strong>El consentimiento prestado.
      </p>
    ),
    twentyfifthParagraph: (
      <p>
        <strong>Destinatarios: </strong>No se realizarán cesiones de datos.
      </p>
    ),
    twentysixthParagraph: (
      <p>
        <strong>Derechos: </strong>Podrá ejercitar los derechos de acceso,
        rectificación, supresión y portabilidad de sus datos, y la limitación u
        oposición a su tratamiento, a revocar el consentimiento prestado y a
        presentar reclamación ante la Agencia Española Protección de Datos.
      </p>
    ),
    twentyseventhParagraph: (
      <p>
        <strong>Información adicional: </strong>Toda la información facilitada
        podrá encontrarla ampliada en la información adicional que está a su
        disposición la política de privacidad
      </p>
    )
  },
  footer: {
    links: {
      firstLink: 'Protección de datos',
      secondLink: 'Certificaciones'
    }
  }
};

export default content;
