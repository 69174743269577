import React from 'react';

import { Link } from 'react-router-dom';

const content = {
  navigation: {
    firstLink: 'Home',
    secondLink: 'EQUANTIA GLOBAL BUSINESS',
    thirdLink: 'Commodities Market',
    fourthLink: 'European Projects',
    fifthLink: 'Third party commerce',
    sixthLink: 'Equantia Group',
    seventhLink: 'EQUANTIA, internationalization',
    eighthLink: 'ADVANTIS, custom software',
    eleventhLink: 'CONFIALIS, data protection',
    twelfthLink: 'CAPITALIZA, money laundering',
    ninthLink: 'Corporate Social Responsibility',
    tenthLink: 'Contact us',
  },
  home: {
    firstParagraph: (
      <p>
        If, at the beginning of each financial year, your company's objective is
        to expansion of its business partners, its sphere of influence, its
        knowledge of the sector and its competitors and, ultimately, to improve
        their positioning in the market, then the EQUANTIA group can offer you
        the to help.
      </p>
    ),
    secondParagraph: (
      <p>
        Thanks to our network of own offices and collaborators your company will
        be able to access a wider market, achieve greater stability in your
        sales, improve your external image and internal, remove cultural
        barriers, diversify risks, offset their results, access new funding
        channels international, learn from external experiences or take
        advantage of international economies of scale at your fingertips.
      </p>
    ),
    newsParagraphTitle: (
      <h3>European Data Protection Regulation (EDPR) - International Transfers</h3>
    ),
    newsParagraph: (
      <p>
      The General Data Protection Regulation EU 2016/679 introduces important new features
      in the system of authorisation and prior notification of international transfers of
      data. If you would like to know more, please contact www.confialis.com.
      </p>
    ),
    newsReadMore: (
      <p>Read more</p>
    ),
  },
  equantiaGlobalBusiness: {
    firstParagraph: (
      <p>
        <strong>EQUANTIA, GLOBAL BUSINESS </strong> is an Organization of
        consultancy founded in 2003 and aimed at facilitating the the
        internationalization of the different business sectors and their and the
        socioeconomic development of these enterprises, both in terms of their
        economic The Committee of the Regions is pleased to note that the
        Commission has been able to provide this information at both the
        national and euro regional level. To this end, it relies on a highly
        qualified human team whose work is based on the Trust, Responsibility,
        Sincerity and Teamwork, as the best answer to the same Trust deposited
        by our customers. customers.
        <br /> <br />
      </p>
    ),
    secondParagraph: (
      <p>
        The relations of EQUANTIA with institutions and companies from other
        countries, are one of the success factors that allow us to make our
        products a reality. project of active collaboration in the
        internationalization of the companies.
      </p>
    ),
    
    mission: {
      firstParagraph: (
        <p>
          <p>
            Obtain high profitability as well as personal development,
            professional and economic of all the members of the Company.
          </p>
        </p>
      ),
      firstTitle: <h4>The Business</h4>,
      secondTitle: 'Culture',
      secondParagraph: (
        <p>
          THE COMPANY will be dedicated to the marketing of services and
          products associated with the internationalization of markets and to
          promote the transnational relations. Our goal is to create value and
          satisfaction for customers on a permanent basis, creating a
          competitive Company that satisfies the wishes of owners, managers and
          employees, and for that: We will make the most of knowledge and skills
          of the personnel, committing ourselves to the continuous improvement
          in both quality of product as in processes, with the aim of making
          them feel Differentiate us with a great brand image and with a great
          reputation. customer loyalty Close relationship with all our
          customers. associates
        </p>
      ),
      thirdParagraph: (
        <p>
          All this will be based on a model of Enterprise Culture governed by:
        </p>
      ),
      list: {
        firstLi: 'Encouraging participation and teamwork',
        secondLi: 'The dialogue',
        thirdLink:
          'The credibility and trust of both internal and external relationships',
        fourthLink: 'Promoting the professional development of all staff'
      },
      fourthParagraph: (
        <p>
          Therefore our company depends on development values, remaining the
          control values for the maintenance and administration of the wealth
          generated, which we base on:
        </p>
      ),
      fifthParagraph:
        'All this leads us to 4 fundamental values of success in our Organization: CRST (Trust, Responsibility, Sincerity and Teamwork).',
      secondList: {
        firstLi:
          'Trust: We provide peace of mind and guarantees at a good price.',
        secondLi:
          'Responsibility: We advance with the contribution of the knowledge of each professional after the in-depth analysis of their work.',
        thirdLi: 'Sincerity: We progress with honesty, dialogue and respect.',
        fourthLi:
          'Teamwork: We share ideas, enthusiasm and experience, to build a great project for everyone.'
      },
      lastParagraph: (
        <p>
          As a consequence of these 4 values, we all contribute:
          self-organization, freedom, creativity, intuition, variety, learning
          from mistakes, enthusiasm, equity, debate, joy, warmth, empowerment,
          provocation and trust.
        </p>
      )
    }
  },
  commoditiesMarket: {
    firstParagraph: (
      <p>
        Our intermediation program establishes a permanent link with companies.
        In volatile markets such as today's, the experience and the in-depth
        analysis of each case, allows us to identify the work in the Commodities
        market, as direct mandataries of buyers and sellers from all continents.
        We can operate with any Commodity and especially in the energy markets,
        and metals.
      </p>
    ),
    secondParagraph: `We investigate the credentials of our interlocutors, prior to any initiation of proceedings. The security of transactions is crucial for our clients and, therefore, for us. The first contact with the "Trading" activities of the EQUANTIA Group must be carried out by means of the e-mail info@equantia.com, and once the veracity of these contacts has been verified, the group's address will communicate directly with the interlocutors.`
  },
  europeanProjects: {
    firstTitle: 'EUROPEAN PROJECTS',
    firstParagraph: (
      <p>
        Obtaining grants for <strong>project financing </strong> doesn't just go
        through the banks he usually works with, the public administration and
        in an important way by the public authorities. European projects.
      </p>
    ),
    secondParagraph: (
      <p>
        Through the management of employment-related European projects
        (training, entrepreneurship, etc.),{' '}
        <strong>
          equality of opportunity, social responsibility, management of the age,
          research in the field of new technologies and the development of the
          information society, transfer of good practices
        </strong>
        solutions to the above-mentioned problems are provided.
      </p>
    ),
    thirdParagraph: (
      <p>
        We put ourselves at the service of associations, public bodies, entities
        the private sector, etc. that have detected a need, lack or in a given
        economic sector or geographical area, and to the extent that the that a
        solution must be found.
      </p>
    ),
    fourthParagraph: (
      <p>
        The framework of these projects is as disparate as the needs themselves.
        of Organizations; going from{' '}
        <strong>
          "Specific research programmes for SMEs", "Approaches change
          management", "Needs analysis", "Change management sectorial", etc.
        </strong>
      </p>
    ),
    fifthParagraph: (
      <p>
        One of the fundamental aspects of this section, in addition to giving
        response to a specific problem, is the{' '}
        <strong>transnational dimension </strong>that these projects have. We
        allows us to get in touch with public and private organizations of other
        countries, for the exchange of experiences and good practices, so that
        the solutions to a specific problem are{' '}
        <strong>much more enriching.</strong>
      </p>
    ),
    sixthParagraph: (
      <p>
        If, at the beginning of each financial year, your company's objective is
        to the internationalisation of companies (knowledge of new forms of
        business) proceed in similar business environments as well as opening
        new ones markets), as it is{' '}
        <strong>
          generate networks of partners whose collaboration can be maintained
          beyond a specific project.
        </strong>
      </p>
    )
  },
  exteriorCommerce: {
    firstTitle: 'Foreign Trade',
    firstParagraph: (
      <p>
        <strong>Equantia </strong>, through a worldwide network of{' '}
        <strong>Partners</strong>, offers you its consulting service for the
        preparation of your Internationalization Plan, fundamental tool and
        basic script for the external development of your company, containing:
      </p>
    ),
    orderedList: {
      firstLi:
        'Tailor-made strategies (Analysis of the exportability of the company and its products)',
      secondLi: 'Feasibility analysis and market studies',
      thirdLi: 'Search for suitable partners or distributors',
      fourthLi: 'We carry out the distribution directly',
      fifthLi: 'Capturing the right investment space',
      sixthLi: 'Search for adequate financial support',
      seventhLi:
        'Creation of companies, selection of personnel, location of offices',
      eighthLi: 'Marketing Strategies',
      ninthLi: 'Maximization of tax benefits'
    },
    secondParagraph: (
      <p>
        Our goal is to become the Export Department of your company. company,
        giving him a personalized treatment with which we establish some{' '}
        <strong>objectives and strategies </strong> for your company.
      </p>
    ),
    thirdParagraph: <p>Our services have three levels:</p>,
    secondOrderedList: {
      firstLi:
        'We establish the necessary contacts with potential clients, and you give continuity to the relationship. Sending commercial surveys, in agreement with you, and sending the conclusions, undoubtedly of great value for decision making in your company.',
      secondLi:
        'We establish the contacts, but this time we conduct all the negotiations, with the necessary trips to the client as well as any necessary management before the client and the public administrations, for the good end of the operation.',
      thirdLi:
        'We act as Commercial Agents, charging a commission for sales, having at all times you the data of market studies, competition, new business possibilities, possibilities of alliances with other companies, etc. that we handle.'
    },
    fourthParagraph: (
      <p>
        Remember that the fundamental objective is not only the{' '}
        <strong>increase in sales </strong>, but the expansion of their
        commercial partners, their influential market, their knowledge of the
        market in general and its competitors, and in short, its{' '}
        <strong>better market positioning.</strong>
      </p>
    )
  },
  equantiaGroup: {
    equantiaParagraph: (
      <p>
        <strong>EQUANTIA </strong> is a Consultant specialized in&nbsp;
        <strong>Foreign Trade </strong> and <strong>European Projects </strong>.
        We carry out complete Internationalization Plans, Missions Individual or
        collective commercials, Search for partners, and Distributors and direct
        commercial intermediation, in any of the following areas countries where
        we have our own offices or strategic alliances. In addition we work with
        socio-economic projects in the European Union, from the creation,
        development and implementation.
      </p>
    ),
    capitalizaParagraph: (
      <p>
        <strong>CAPITALIZA</strong> is a consulting organization oriented to facilitate the
        change processes within organizations and to transform the processes of
        current management models to enable levels of productivity and
        productivity. superior profitability. We perform Strategic Consulting
        and Auditing in the area of Corporate Social Responsibility.
        Implementation of Prevention of Money Laundering, CSR management
        systems, Equality Plans, Ethical Codes, Sustainability Reports, Social
        programs and implementation of the SGE21 standard.
      </p>
    ),
    advantisParagraph: (
      <p>
        <strong>ADVANTIS CORPORATE</strong> is a company specialized in
        providing services of custom software programming and mobile
        applications. We focused, mainly, on transforming the needs of the
        clients. in functional products assuring quality, support and services
        after-sales service. We develop all types of Apps, for IOS and Android.
      </p>
    ),
    confialisParagraph: (
      <p><strong>CONFIALIS</strong> provide their services in accordance with current legislation on data protection: General Data Protection Regulations (RGPD) and the Organic Law on the Protection of Personal Data and other regulations indirectly applicable to the protection of personal data. We complete the application of these regulations with the guidelines established in Law 34/2002, of 11 July, on information society services and electronic commerce.</p>
    )
  },
  responsibilities: {
    title: 'RESPONSABILIDAD SOCIAL CORPORATIVA',
    firstParagraph: (
      <p>
        <strong>Equantia Global Business, S.L.</strong> has affirmed its ethical
        commitment in all its management areas. This commitment integrates the
        values it has adopted as well as its own (trust, confidence, trust
        responsibility, sincerity and teamwork), as well as mission and mission.
        vision of the company..,
      </p>
    ),
    secondParagraph: (
      <p>
        And from this commitment derives the{' '}
        <strong>Ethical and Socially Responsible Management Policy </strong>{' '}
        that regulate the company's Ethical Management System. This policy is
        governed by the following clauses:
      </p>
    ),
    orderedList: {
      firstLi: `Compliance with the legal system in force, both formally and materially, and effectiveness of the commitments acquired in environmental and socioeconomic matters. Transmission of the company's commitment to the company's interested parties.`,
      secondLi: `Information on the company's ethical commitment to customers through the hiring document and the provision of services aimed at fulfilling the mission established by Equantia Global Business, S.L.`,
      thirdLi:
        'Require collaborating companies, contractors or suppliers to comply with environmental and social legislation. This requirement will materialize through the policy of purchasing and contracting goods and services.',
      fourthLi: `Development of the principles of the Ethical Management System in all areas of the company's activity, with objectives and specific periodic action programmes. They will be complementary to the other policies, especially with regard to quality and management of human resources.`,
      fifthLi:
        'Establishment, by the company, of mechanisms for monitoring the ethical management system, based on self-control, and on the use of procedures for the attribution of responsibilities and supervision of management..',
      sixthLi:
        'Efficient use of resources, such as water or energy, minimizing consumption, waste generation and levels of pollution and impact.',
      seventhLi: `Support for the adoption of specific commitments for continuous environmental and social improvement based on the implementation of programmes, certification and periodic auditing of the company's ethical management system.`,
      eighthLi: `Implementation of measures for the training, awareness and motivation of the staff of Equantia Global Business, S.L. The company's commitment to the ethical management of human resources is ratified, based on compliance with labour regulations and risk prevention, the guarantee of equality in the selection and treatment of staff, regardless of gender, ethnic group or any other social condition, the adoption of measures to reconcile work and family life and the attention to their needs and the satisfaction of their commitments.`
    },
    thirdParagraph:
      'The Management of Equantia Global Business, S.L., endorses this policy and is committed to promoting it.',
    fourthParagraph: (
      <p>
        If you wish to consult our Code of Ethical and Social Conduct Person in
        charge request it going to the mail
      </p>
    )
  },
  contact: {
    title: 'CONTACT WITH EQUANTIA',
    formName: 'Name and surname',
    formMessageSubject: 'Message subject',
    formMessage: 'Write your message',
    formAcceptTerms: (
      <>
        I have read the <Link to='/legal-terms'>LEGAL POLICY</Link> and I accept{' '}
      </>
    ),
    formSubmitBtnText: 'Submit'
  },
  footer: {
    links: {
      firstLink: 'Data protection',
      secondLink: 'Certifications'
    }
  },
  privacyPolicy: {
    title: 'PRIVACY POLICY',
    firstParagraph: (
      <p>
        <strong>EQUANTIA GLOBAL BUSINESS, S.L.</strong> in compliance with the
        Law 34/2002, of 11 July, on Services of the Society of the Information
        and Electronic Commerce, informs you:
      </p>
    ),
    firstOrderedList: {
      firstLink: 'Its global denomination EQUANTIA GLOBAL BUSINESS, S.L.',
      secondLink: 'Its NIF is B36932135',
      thirdLink:
        'His address is Calle Aragón 197 Interior, 36206 Vigo (Pontevedra)',
      fourthLink: 'Its phone number is 986 139 007',
      fifthLink:
        'It is inscribed in the Mercantile Registry of Pontevedra, Volume 2918, Folio 195, 1st Inscription, of Sheet nº PO-34171.',
      sixthLink: (
        <>
          And you have the following contact email address
          <a href='mailto:equantia@equantia.com'> equantia@equantia.com'</a>
        </>
      )
    },
    secondTitle: 'PROTECTION OF PERSONAL DATA',
    secondParagraph:
      'The personal data linked to this website comply with the requirements of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and other legislation and regulations in force in the field of data protection.',
    thirdTitle: 'Who is Responsible for Treatment?',
    address: 'Address: Calle Aragón 197 Interior, 36206 Vigo (Pontevedra)',
    mail: 'Email Address:',
    fourthTitle: '¿For what purpose do we process your personal data??',
    thirdParagraph:
      'In accordance with the different sections of our website, we treat your data with the following in order to manage the sending of the requested information through the contact section.',
    fifthTitle: '¿For how long will we keep your data?',
    fourthParagraph:
      'Your data will be kept for the time necessary to meet your request and, where appropriate, sending the information requested.',
    sixthTitle: 'What is the legitimacy of the processing of your data?',
    fifthParagraph:
      'The legal basis for the processing of your data will be the consent given by using the contact form.',
    seventhTitle: 'To which recipients will your data be communicated?',
    sixthParagraph: 'No data will be communicated to third parties.',
    eighthTitle: '¿Will data be transferred to third countries?',
    seventhParagraph: 'There will be no International Data Transfers.',
    ninthTitle:
      'What are your rights when you provide us with your personal data?',
    secondList: {
      firstLi:
        'Access: the right to obtain confirmation as to whether or not we are processing personal data about you, i.e., what data is being used for, how long it will be stored, the origin of the data, and whether it has been or will be communicated to a third party.',
      secondLi:
        'Rectification: the right to request rectification of inaccurate data and completion of incomplete personal data.',
      thirdLi:
        'Deletion: the right to request the deletion of personal data when they are inadequate, excessive or no longer necessary for the purposes for which they were collected, including the right to oblivion.',
      fourthLi:
        'Opposition: the right to oppose, in certain circumstances, the processing of your personal data or to request that the processing cease.',
      fifthLi:
        'Treatment limitation: the right to request, in legally established circumstances, that your data not be processed beyond its mere conservation.',
      sixthLi:
        'Portability: the right to receive personal data in a structured format, commonly used and machine-readable, and to be able to transmit them to another party responsible, whenever technically possible.'
    },
    tenthTitle: 'Will you be able to withdraw consent?',
    ninthParagraph:
      'He shall have the possibility and the right to withdraw his consent for any specific purpose given at the time, without this affecting the lawfulness of the processing based on the consent prior to its withdrawal.',
    eleventhTitle: 'Where can you exercise your rights?',
    tenthParagraph:
      'The exercise of rights must be carried out in writing, attaching a photocopy of your identification document, and send it to the aforementioned address. Likewise, we provide you with models for your exercise, which can be collected at our facilities or requested by sending an email to',
    twelfthParagraph: 'For more info: www.agpd.es',
    thirteenthParagraph:
      'Right to complain to the Control Authority: In the event that your rights have not been respected, you may submit a complaint in writing to the Spanish Data Protection Agency located at Calle Jorge Juan, 6-28001-Madrid, or use the website: https://sedeagpd.gob.',
    fourteenthParagraph:
      'In both cases, it must be accompanied by the relevant documentation.',
    twelfthTitle: 'Access and contents of the website',
    fifteenthParagraph: `Access to the website is public and the information contained therein is merely informative. The company's policy is to try to ensure that the contents are always up to date, but it may happen that they are not.`,
    sixteenthParagraph:
      'The function of the links that appear on this page is exclusively to inform users and customers about the existence of other sources of information on the subject of the Internet, where you can expand or complete the data offered on this page.',
    seventeenthParagraph:
      'In any case EQUANTIA does not assume responsibility derived from the contents linked from its web page, nor can it guarantee the absence of virus or other elements in the same that can produce alterations in the computer system, in the documents or files of the user, excluding any responsibility for damages of any kind caused to the user for this reason.',
    thirteenthTitle: 'Intellectual Property',
    eighteenthParagraph:
      'The intellectual property rights of the published articles are those of EQUANTIA. The unauthorized use of the information contained in this website, as well as the infringement of the Intellectual or Industrial Property rights of EQUANTIA, will give rise to the legally established responsibilities. Both access to this website and the use that can be made of the information contained in it, is the sole responsibility of whoever does.',
    fourteenthTitle: 'Responsibilities',
    nineteenthParagraph:
      'EQUANTIA does not guarantee the inexistence of errors in the access to the Web site or in its content, trying in any case that this one is updated, although it will employ all the measures that are within its reach to avoid them or to correct them.',
    fifteenthTitle: 'Applicable law',
    twentiethParagraph: 'This Legal Notice is governed by Spanish law.',
    sixteenthTitle: 'BASIC INFORMATION WEB CONTACT FORMS',
    twentyfirstParagraph: (
      <p>
        <strong>Normative: </strong>Rules of Procedure (UE) 2016/679 RGPD.
      </p>
    ),
    twentysecondParagraph: (
      <p>
        <strong>Responsible: EQUANTIA GLOBAL BUSINESS, S.L.</strong>
      </p>
    ),
    twentythirdParagraph: (
      <p>
        <strong>Purpose: </strong>Manage requests for information that are
        carried out through the website.
      </p>
    ),
    twentyfourthParagraph: (
      <p>
        <strong>Legitimation: </strong>Consent given.
      </p>
    ),
    twentyfifthParagraph: (
      <p>
        <strong>Destinataries: </strong>There will be no data transfers.
      </p>
    ),
    twentysixthParagraph: (
      <p>
        <strong>Rights: </strong>You can exercise access rights, rectification,
        deletion and portability of your data, and the limitation or opposition
        to its processing, to revoking the consent given and to file a complaint
        with the Spanish Data Protection Agency.
      </p>
    ),
    twentyseventhParagraph: (
      <p>
        <strong>Additional information: </strong>All information provided you
        can find it enlarged in the additional information that is to your
        provision of the privacy policy
      </p>
    )
  }
};

export default content;
