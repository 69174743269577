import React from 'react';
import { connect } from 'react-redux';

import './index.css';

class LegalTerms extends React.Component {
  render() {
    const { language } = this.props;

    const languageContent = require(`../../langs/${language}`).default
      .privacyPolicy;

    return (
      <div className='legal-terms__wrapper'>
        <h2>{languageContent.title}</h2>
        <br />
        <p>{languageContent.firstParagraph.props.children}</p>
        <br />
        <ul className='legal-terms'>
          <li>{languageContent.firstOrderedList.firstLink}</li>
          <li>{languageContent.firstOrderedList.secondLink}</li>
          <li>{languageContent.firstOrderedList.thirdLink}</li>
          <li>{languageContent.firstOrderedList.fourthLink}</li>
          <li>{languageContent.firstOrderedList.fifthLink}</li>
          <li>{languageContent.firstOrderedList.sixthLink}</li>
        </ul>
        <br />
        <h4>{languageContent.secondTitle}</h4>
        <br />
        <p>{languageContent.secondParagraph}</p>
        <br />
        <h4>{languageContent.thirdTitle}</h4>
        <br />
        <p>EQUANTIA GLOBAL BUSINESS, S.L.</p>
        <br />
        <p>CIF: B36932135</p>
        <br />
        <p>{languageContent.address}</p>
        <br />
        <p>
          {languageContent.mail}
          <a href='equantia@equantia.com'> equantia@equantia.com</a>
        </p>
        <br />
        <h4>{languageContent.fourthTitle}</h4>
        <br />
        <p>{languageContent.thirdParagraph}</p>
        <br />
        <h4>{languageContent.fifthTitle}</h4>
        <br />
        <p>{languageContent.fourthParagraph}</p>
        <br />
        <h4>{languageContent.sixthTitle}</h4>
        <br />
        <p>{languageContent.fifthParagraph}</p>
        <br />
        <h4>{languageContent.seventhTitle}</h4>
        <br />
        <p>{languageContent.sixthParagraph}</p>
        <br />
        <h4>{languageContent.eighthTitle}</h4>
        <br />
        <p>{languageContent.seventhParagraph}</p>
        <br />
        <h4>{languageContent.ninthTitle}</h4>
        <br />
        <ul className='legal-terms'>
          <li>{languageContent.secondList.firstLi}</li>
          <li>{languageContent.secondList.secondLi}</li>
          <li>{languageContent.secondList.thirdLi}</li>
          <li>{languageContent.secondList.fourthLi}</li>
          <li>{languageContent.secondList.fifthLi}</li>
          <li>{languageContent.secondList.sixthLi}</li>
        </ul>
        <br />
        <h4>{languageContent.tenthTitle}</h4>
        <br />
        <p>{languageContent.ninthParagraph}</p>
        <br />
        <h4>{languageContent.eleventhTitle}</h4>
        <br />
        <p>
          {languageContent.tenthParagraph}
          <a href='mailto:equantia@equantia.com'> equantia@equantia.com</a>
        </p>
        <br />
        <p>{languageContent.twelfthParagraph}</p>
        <br />
        <p>{languageContent.thirteenthParagraph}</p>
        <br />
        <p>{languageContent.fourteenthParagraph}</p>
        <br />
        <h4>{languageContent.twelfthTitle}</h4>
        <br />
        <p>
          {languageContent.fifteenthParagraph}
          <br /> {languageContent.sixteenthParagraph}
          <br /> {languageContent.seventeenthParagraph}
        </p>
        <br />
        <h4>{languageContent.thirteenthTitle}</h4>
        <br />
        <p>{languageContent.eighteenthParagraph}</p>
        <br />
        <h4>{languageContent.fourteenthTitle}</h4>
        <br />
        <p>{languageContent.nineteenthParagraph}</p>
        <br />
        <h4>{languageContent.fifteenthTitle}</h4>
        <br />
        <p>{languageContent.twentiethParagraph}</p>
        <br />
        <h3>{languageContent.sixteenthTitle}</h3>
        <br />
        <div className='contact-web-info'>
          <p>{languageContent.twentyfirstParagraph.props.children}</p>
          <br />
          <p>{languageContent.twentysecondParagraph.props.children}</p>
          <br />
          <p>{languageContent.twentythirdParagraph.props.children}</p>
          <br />
          <p>{languageContent.twentyfourthParagraph.props.children}</p>
          <br />
          <p>{languageContent.twentyfifthParagraph.props.children}</p>
          <br />
          <p>{languageContent.twentysixthParagraph.props.children}</p>
          <br />
          <p>{languageContent.twentyseventhParagraph.props.children}</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language.lang
  };
};

export default connect(
  mapStateToProps,
  null
)(LegalTerms);
