import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './style.css';

class Footer extends React.Component {
  render() {
    const { language } = this.props;

    const languageContent = require(`../../langs/${language}`).default.footer;

    return (
      <footer className='main__footer'>
        <div className='upper-links'>
          <Link to='/legal-terms'>{languageContent.links.firstLink}</Link>
          <Link to='/certificates'>{languageContent.links.secondLink}</Link>
        </div>
        <div className='down-links'>
          &copy; Equantia Global Business S.L.· Aragón 197, 36206 Vigo (Spain) ·
          Tel. 902 995 810 · +34 986 139 007 · Fax. +34 886 113 730 ·
          equantia@equantia.com
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language.lang
  };
};

export default connect(
  mapStateToProps,
  null
)(Footer);
