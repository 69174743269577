import { CHANGE_LANGUAGE, REVERT_LANGUAGE } from './types';

export const changeLanguage = lang => {
  return {
    type: CHANGE_LANGUAGE,
    payload: lang
  };
};

export const revertLanguage = () => {
  return {
    type: REVERT_LANGUAGE
  };
};
