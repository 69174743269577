import React from 'react';
import { connect } from 'react-redux';

import './style.css';

class ExteriorCommerce extends React.Component {
  render() {
    const { language } = this.props;

    const languageContent = require(`../../langs/${language}`).default
      .exteriorCommerce;

    return (
      <div className='exterior-commerce__wrapper'>
        <h2>{languageContent.firstTitle}</h2>
        <br />
        <p>{languageContent.firstParagraph.props.children}</p>
        <br />
        <ol>
          <li>{languageContent.orderedList.firstLi}</li>
          <li>{languageContent.orderedList.secondLi}</li>
          <li>{languageContent.orderedList.thirdLi}</li>
          <li>{languageContent.orderedList.fourthLi}</li>
          <li>{languageContent.orderedList.fifthLi}</li>
          <li>{languageContent.orderedList.sixthLi}</li>
          <li>{languageContent.orderedList.seventhLi}</li>
          <li>{languageContent.orderedList.eighthLi}</li>
          <li>{languageContent.orderedList.ninthLi}</li>
        </ol>
        <br />
        <p>{languageContent.secondParagraph.props.children}</p>
        <br />
        <p>{languageContent.thirdParagraph.props.children}</p>
        <br />
        <ol>
          <li>{languageContent.secondOrderedList.firstLi}</li>
          <br />
          <li>{languageContent.secondOrderedList.secondLi}</li>
          <br />
          <li>{languageContent.secondOrderedList.thirdLi}</li>
        </ol>
        <br />
        <p>{languageContent.fourthParagraph.props.children}</p>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language.lang
  };
};

export default connect(
  mapStateToProps,
  null
)(ExteriorCommerce);
