import { REVERT_LANGUAGE, CHANGE_LANGUAGE } from '../actions/types';

const INITIAL_STATE = {
  lang: 'en'
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return { ...state, lang: action.payload };
    case REVERT_LANGUAGE:
      return { ...state, lang: null };
    default:
      return state;
  }
};
